html,
body,
#root {
  color: #202020;
  font-family: Lato, serif;
  font-style: normal;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  outline: none;
}

.link {
  font-weight: 600;
  font-size: 15px;
  color: #4f6fa5;
}

.container {
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 30px;
  @media screen and (max-width: 700px) {
    padding: 0 10px;
  }
}

.d-fl {
  display: flex;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.df-col {
  display: flex;
  flex-direction: column;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.d-flex-w {
  flex-wrap: wrap;
}

.cssLine {
  width: 100%;
  opacity: 0.5;
  border: 1px solid #94A0B7;
}

.mar-right-1 {
  margin-right: 1px;
}

.mar-right-5 {
  margin-right: 5px;
}

.mar-left-5 {
  margin-left: 5px;
}

.mar-right-9 {
  margin-right: 9px;
}

.mar-right-10 {
  margin-right: 10px;
}

.mar-top-10 {
  margin-top: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pad-3-0 {
  padding: 3px 0;
}

.pl-20 {
  padding-left: 20px;
}

.header-link {
  a {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #2D3C43;
  }
}

.d-flex-100 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.block-title {
  margin-left: 8px;

  a {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #2D3C43 !important;
  }
}

.title-fs-24 {
  font-size: 24px !important;
  @media screen and (max-width: 900px) {
    font-size: 17px !important;
  }
}

.mr-l-80 {
  margin-left: 80px;
}

.mr-l-200 {
  margin-left: 200px;
}

.red {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  background: #F14D49 !important;
  padding: 2px 10px !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
}

.green {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  background: #27AE60 !important;
  padding: 2px 10px !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;

  a {
    color: #333 !important;
  }
}

.amber {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  background: #F47E21 !important;
  padding: 2px 10px !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;

  a {
    color: #333 !important;
  }
}

.blue {
  cursor: pointer;
  background: #2F80ED !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  padding: 2px 10px !important;
}

$black: #000;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
