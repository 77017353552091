.checkbox {
  margin-right: 10px;

  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #A9CC13;
      border-radius: 5px;
    }

    .ant-checkbox-inner {
      border-radius: 5px;
    }
  }
}

.headerBlock {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  background-image: linear-gradient(112.81deg, rgba(0, 61, 54, 0.8) 17.55%, rgba(78, 141, 68, 0.8) 90.03%), url('../../assets/img/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  margin-top: 150px;

  @media screen and (max-width: 700px), (max-height: 900px) {
    margin-top: 50px;
  }
}

.informationBlockWrap {
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(124, 144, 177, 0.3);
  border-radius: 20px;
  width: 80vw;
  margin: 0 auto;
  padding: 50px 40px;
}

.checkboxWrap {
  margin-bottom: 40px;
}

.checkboxText {
  margin-left: 8px;
  cursor: pointer;
}

.ruleWrap {
  display: flex;
  font-size: 14px;
  text-align: center;
  align-items: center;
  color: #7C90B1;

  @media screen and (max-width: 780px), (max-height: 700px) {
    font-size: 10px;
  }
}

.error {
  color: red;
}

.normal {
  color: black;
}

.dote {
  min-width: 5px;
  min-height: 5px;
  border-radius: 50%;
  background-color: #7C90B1;
  margin-right: 5px;
}

.passwordFormWrap {
  padding: 20px;
  background: rgba(255, 255, 255, 0.7);
}

.container {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 780px), (max-height: 700px) {
    justify-content: center !important;
  }
}

.inputPasswordWrap {
  width: 45%;
 margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1400px), (max-height: 700px) {
    width: 40%;
  }

  @media screen and (max-width: 780px), (max-height: 700px) {
    width: 80%;
  }
}

.inputTitleWrap {
  margin-bottom: 15px;
}

.inputTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2D3C43;
}

.mainTitleWrap {
  margin-bottom: 25px;
}

.mainTitle {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  color: #014B3E;
}

.mainDesc {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #2D3C43;
}

.passwordInput {
  background: #FFFFFF;
  border: 1px solid #D3DCE6;
  border-radius: 5px;
}

.buttonWrap {
  margin-top: 70px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 780px), (max-height: 700px) {
    margin-top: 20px;
  }
}

.termsWrap {
  .termsBlockWrap {
    margin-bottom: 40px;
  }

  .rulesBlockWrap {
    margin-bottom: 2px;
  }

  .termsSubTitle {
    color: #2D3C43;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .link {
    color: #27AE60;
  }

  .termsRule {
    font-weight: 700;
  }

  .termsContent {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #2D3C43;
    margin-bottom: 0 !important;
  }
}

.userActivationAlert {
  :global {
    position: absolute !important;
    top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 30% !important;
    right: 30% !important;
    text-align: center !important;
    border-radius: 15px !important;
    background-color: rgba(245, 0, 7, 0.31) !important;
    border: 1px solid red !important;
  }
}

